<template>
    <div class="root">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{$route.name}}</span>
            </div>

            <!-- 搜索表单 -->
            <el-form :inline="true" :model="searchData">
                <el-form-item label="平台">
                    <el-select v-model="searchData.source_id" placeholder="请选择" size="small">
                        <el-option label="" value=""></el-option>
                        <el-option v-for="(item, i) in sourceData" :key="'sort' + i" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" plain @click="search">查询</el-button>
                </el-form-item>
            </el-form>

            <!-- 表格 -->
            <el-table :data="tableData" stripe style="width: 100%" v-loading="tableloading">
                <!-- 表格数据列 -->
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="source_name" label="平台名"></el-table-column>
                <el-table-column prop="content" label="错误信息" min-width="350"></el-table-column>
                <el-table-column label="创建日期">
                    <template slot-scope="item">
                        {{base.formatTime(new Date(item.row.create_time))}}
                    </template>
                </el-table-column>
            </el-table>

            <!-- 表格分页 -->
            <div class="pager">
                <el-pagination background layout="prev, pager, next" page-size="20" :total="pageTotal" :current-page="searchData.page" @current-change="changePage"></el-pagination>
            </div>

        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // 搜索数据
                searchData: {
                    source_id: '',
                    page: 1,
                    size: 20
                },
                // 表格数据
                pageTotal: 0,
                tableloading: true,
                tableData: [],
                sourceData: [],
            }
        },
        created() {
            this.loadTable()
            this.loadSource()
        },
        methods: {
            // 表格请求
            loadTable() {
                let that = this
                this.ajax({
                    url: '/hot/craw_error',
                    data: that.searchData,
                    beforeSend() {
                        that.tableloading = true
                    },
                    complete() {
                        that.tableloading = false
                    },
                    success(res) {
                        that.pageTotal = res.data.count
                        that.tableData = res.data.list
                    },
                    error(res) {
                        that.$message.error(res.message);
                    },
                })
            },
            // 所有平台请求
            loadSource() {
                let that = this
                that.ajax({
                    url: '/hot/source_all',
                    success(res) {
                        that.sourceData = res.data
                    },
                })
            },
            // 搜索
            search() {
                this.searchData.page = 1
                this.loadTable()
            },
            // 翻页
            changePage(page) {
                this.searchData.page = page
                this.loadTable()
            },
        }
    }
</script>

<style lang="less" scoped>
    .pager {
        padding-top: 20px;
        text-align: right;
    }
</style>